// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-bio-js": () => import("./../../../src/pages/bio.js" /* webpackChunkName: "component---src-pages-bio-js" */),
  "component---src-pages-desichain-js": () => import("./../../../src/pages/desichain.js" /* webpackChunkName: "component---src-pages-desichain-js" */),
  "component---src-pages-hire-js": () => import("./../../../src/pages/hire.js" /* webpackChunkName: "component---src-pages-hire-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mitm-js": () => import("./../../../src/pages/mitm.js" /* webpackChunkName: "component---src-pages-mitm-js" */),
  "component---src-pages-press-js": () => import("./../../../src/pages/press.js" /* webpackChunkName: "component---src-pages-press-js" */),
  "component---src-pages-tour-js": () => import("./../../../src/pages/tour.js" /* webpackChunkName: "component---src-pages-tour-js" */),
  "component---src-pages-videos-js": () => import("./../../../src/pages/videos.js" /* webpackChunkName: "component---src-pages-videos-js" */),
  "component---src-templates-booking-type-js": () => import("./../../../src/templates/booking_type.js" /* webpackChunkName: "component---src-templates-booking-type-js" */)
}

